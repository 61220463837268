<template>
  <div class="page-info-content">
    <el-tabs v-model="editableTabsValue" @tab-click="changeTabs">
      <el-tab-pane v-for="(item, index) in editableTabs" :key="index" :label="item" :name="`${index}`">
        <!-- 表格  分页 -->
        <Table :item-data="financialDetailsItemData" :list-data="financialDetailsListData" />
        <!-- 分页 -->
        <Pagination
          :params="financialDetailsFormInline"
          :total="financialDetailsTotal"
          :in-article="financialDetailsListData.length"
          :get-data-list="getFundDetails"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { flowDetailPage } from '@/api/accountManagementSystem'
import { timestampToTime } from '@/utils/util'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table'
export default {
  components: {
    Pagination,
    Table
  },
  props: {

  },
  data() {
    return {
      financialDetailsFormInline: {
        pageSize: 10,
        pageNum: 1
      },
      editableTabsValue: 0, // tab 第几页
      editableTabs: ['全部', '充值', '提现', '交易'],
      financialDetailsItemData: [
        { label: '流水号', prop: 'id', width: 160 },
        { label: '业务单号', prop: 'busiNo', width: 160 },
        { label: '操作时间', prop: 'createTime', width: 160 },
        { label: '资金科目', prop: 'itemType', child: [{ value: 1, label: '充值' }, { value: 2, label: '提现' }, { value: 3, label: '冻结' }, { value: 4, label: '解冻' }, { value: 5, label: '支付' }, { value: 6, label: '收入' }] },
        { label: '交易金额', prop: 'amount', type: 'money' },
        { label: '可用余额', prop: 'endAvlbAmt', type: 'money' }
      ],
      financialDetailsTotal: 0,
      financialDetailsListData: []
    }
  },
  created() {
    this.financialDetailsFormInline = this.$route.query
    this.getFundDetails()
  },
  methods: {
    changeTabs() {
      switch (this.editableTabsValue) {
        case '1':
          this.financialDetailsFormInline.itemType = 1
          break
        case '2':
          this.financialDetailsFormInline.itemType = 2
          break
        case '3':
          this.financialDetailsFormInline.itemType = -1
          break

        default:
          this.financialDetailsFormInline.itemType = ''
          break
      }
      this.getFundDetails()
    },
    getFundDetails(type) {
      if (!type) this.financialDetailsFormInline.pageNum = 1
      flowDetailPage(this.financialDetailsFormInline, res => {
        this.financialDetailsListData = [...res.data.pageData]
        this.financialDetailsTotal = res.data.total
        this.financialDetailsListData.forEach(item => {
          item.createTime = timestampToTime(item.createTime)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
